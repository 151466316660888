/* html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
} */

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
  font-size: 100%;
}

:root {
  /* font-size: min(max(1rem, calc(1rem + ((1vw - 0.48rem) * 1.3889))), 2rem); */
  min-height: 0vw;
}

html, body {
  /* font-size: 100%; */
  -webkit-text-size-adjust: none;
  /* equivalent to 16px */
}

@font-face {
  font-family: "Grinched";
  src: url("./fonts/GrinchedRegular.otf") format("opentype");
}

h1, h2, h3, h4 {
  font-family: "Grinched";
}

@font-face {
  font-family: "Gill Sans MTPro";
  src: url("./fonts/GillSansMTPro-Book.otf") format("opentype");
}

body {
  font-family: "Gill Sans MTPro" !important;
}

p {
  margin-top: 0;
  line-height: 1;
  margin-bottom: 0.33rem !important;
}

.story_title {
  font-size: 2rem;
  /* margin-top: 8rem; */
  z-index: 1;
  /* position: absolute; */
  white-space: nowrap;
}

.story-text {
  position: absolute;
  display: none;
}

.container-fluid {
  background-color: #f9f9ef;
  background-color: black;
}

.descr-intro {
  font-size: x-large;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  padding: 1rem;
  margin-bottom: 2rem;
}

.story_1 .modal-dialog .modal-content {
  background-image: url("/images/domande/domanda-01.png");
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
}

.story_2 .modal-dialog .modal-content {
  background-image: url("/images/domande/domanda-02.png");
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
}

.story_3 .modal-dialog .modal-content {
  background-image: url("/images/domande/domanda-03.png");
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
}

.story_4 .modal-dialog .modal-content {
  background-image: url("/images/domande/domanda-04.png");
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
}

.story_5 .modal-dialog .modal-content {
  background-image: url("/images/domande/domanda-05.png");
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
}

.story_6 .modal-dialog .modal-content {
  background-image: url("/images/domande/domanda-06.png");
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
}

.story_7 .modal-dialog .modal-content {
  background-image: url("/images/domande/domanda-07.png");
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
}

.story_8 .modal-dialog .modal-content {
  background-image: url("/images/domande/domanda-08.png");
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
}

.story_9 .modal-dialog .modal-content {
  background-image: url("/images/domande/domanda-09.png");
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
}

.story_10 .modal-dialog .modal-content {
  background-image: url("/images/domande/domanda-10.png");
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
}

.modal-content, .modal-footer, .modal-header, .modal-body, .modal-dialog {
  border: none !important;
  border-bottom: none !important;
  border-top: none !important;
}

.descr-intro {
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  flex: 0 1 auto !important;
}

/* .modal-dialog{
  max-width: 40vw;
} */

.modal-content {
  min-height: 33rem;
}

.row:before, .row:after {
  display: inline-block;
}

.modal-content {
  position: relative;
}

.modal-footer {
  /* flex: 1; */
  /* position: absolute;
  bottom: 0; */
  align-self: end;
  width: 100%;
  flex-shrink: 0;
}

.storia {
  width: 27vw;
  height: 27vh;
  /* max-width: 30rem; */
  /* max-height: 18rem; */
}

.storia>img {
  position: absolute;
  width: 100%;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -70%); */
  margin: 0 auto;
}

.modal-body {
  /* max-height: 16rem; */
  flex: 1 0 auto !important;
  height: 100%;
  width: 100%;
  text-align: center;
}

.vertical-alignment-helper {
  display: table;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

/* .modal-dialog-centered {

  display: table-cell;
  vertical-align: middle;
  pointer-events: none;
} */

.modal-content {
  /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
  width: inherit;
  max-width: inherit;
  /* For Bootstrap 4 - to avoid the modal window stretching
full width */
  height: inherit;
  /* To center horizontally */
  margin: 0 auto;
  pointer-events: all;
}

.cont {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.modal-footer {
  margin-bottom: 3rem;
}

.modal-header {
  margin-top: 2rem;
}

.question_text {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.result_text {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
}

.intro_card {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

.outro_card {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

@-webkit-keyframes fadeIn {
  0% {
    /* position: absolute; */
    /* top: -3rem; */
    opacity: 0;
  }
  75% {
    /* position: absolute; */
    /* top: 25%; */
    opacity: 50;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    /* position: absolute; */
    /* top: -3rem; */
    opacity: 0;
  }
  75% {
    /* position: absolute; */
    /* top: 25%; */
    opacity: 50;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    /* position: absolute; */
    /* top: -3rem; */
    opacity: 1;
  }
  75% {
    /* position: absolute; */
    /* top: 25%; */
    opacity: 50;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    /* position: absolute; */
    /* top: -3rem; */
    opacity: 1;
  }
  75% {
    /* position: absolute; */
    /* top: 25%; */
    opacity: 50;
  }
  100% {
    opacity: 0;
  }
}

.risposta_corretta {
  position: absolute;
  width: 17rem;
  right: 0;
  margin-right: -9rem;
  margin-bottom: -8rem;
  bottom: 0;
}

.backgound {
  /* color: #999999;
  position: absolute;
  top: 0;
  left: 0; */
  /* z-index: -100; */
}

.bottone_img:hover {
  box-shadow: black;
}

.bottone_img:active {
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.bottone_img {
  z-index: 1;
}

.apri-image {
  position: absolute;
  bottom: 0;
}

.close {
  background-image: url("/images/domande/bottone-close.png");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 6;
  margin-right: 2rem !important;
  margin-top: -0.5rem !important;
}

.close span {
  display: none;
}

.image {
  display: inline-block;
  width: auto;
  height: 100%;
  /* min-width: 100vw; */
  max-height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);
  max-width: 100vw;
  margin: 0;
  -o-object-fit: contain;
  object-fit: contain;
}

.container-fix {
  box-sizing: border-box;
  padding: 0 !important;
  /* width: 100vw !important; */
  margin: 0 !important;
}

body {
  width: 100vw;
  background-color: black;
}

#root {
  /* width: 100vw !important; */
  margin: 0 !important;
  padding: 0 !important;
}

.slide_prot {
  overflow-y: hidden;
  overflow-x: hidden;
  display: inline-block;
  width: auto;
  height: 100%;
  /* min-width: 100vw; */
  max-width: 100vw;
  max-height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);
  margin: 0;
  padding: 0;
  scroll-snap-align: start;
  scroll-behavior: smooth;
  flex-shrink: 0;
}

.image-container {
  vertical-align: middle;
  display: inline-block;
  display: flex;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  right: 0;
  position: absolute;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  /* bottom: 0; */
  /* height: 100vh; */
  /* height: calc(var(--vh, 1vh) * 100); */
}

.fullpage {
  width: 100vw !important;
  height: 100vh !important;
  position: absolute;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none;
  top: 0;
}

.answer_button {
  /* width: 15rem; */
  border: none !important;
  background-color: transparent !important;
  /* height: 8rem; */
}

.avanti_button {
  /* width: 15rem; */
  border: none !important;
  background-color: transparent !important;
  /* height: 5rem; */
  position: absolute;
  top: 1vh;
  right: 1vw;
}

.result_button {
  /* width: 25rem; */
  border: none !important;
  background-color: transparent !important;
  /* height: 15rem; */
  position: absolute;
  top: 14vh;
  right: 13vw;
}

.result_text {
  /* position: relative;
width: 50vw;
top: 10vw;
font-size: x-large;
margin-left: 22rem; */
  /* position: relative; */
  width: 44vw;
  top: 5vw;
  font-size: x-large;
  /* margin-left: 9vw; */
  margin-left: 31vw !important;
}

.titolo_home {
  flex: 0 1 auto;
}

.home-flex {
  width: 100%;
  height: 100%;
}

.result_title {
  /* position: relative;
width: 35vw;
top: 5vw;
font-size: x-large;
margin-left: 30vw;
margin-top: 1rem; */
  /* position: relative; */
  width: 35vw;
  top: 5vw;
  font-size: x-large;
  padding-bottom: 2rem;
  /* margin-left: 30vw; */
  margin-top: 0rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-left: 31vw !important;
}

.storia {
  position: relative;
}

/* .storia {
  position: absolute;
  top: 0;
  transform: translateY(50%);
} */

.result-container {
  position: absolute;
  width: 100%;
  height: 60vh;
  padding-left: 5vw;
  /* padding-top: 9rem; */
  margin: 0;
  /* position: absolute; */
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.bott_domande {
  width: 13rem;
  height: 6rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* .row::before, .row::after{
  display: inline-block;
  content: none;
} */

.kaspersky {
  width: 20rem;
  /* margin-top: 10rem; */
  flex: 0 1 auto;
  /* position: absolute;
  bottom:-8rem; */
}

.size-fix {
  /* height: 100%; */
  /* height: calc(var(--vh, 1vh) * 100); */
  width: 100%;
  position: relative;
}

.res {
  font-size: xx-large;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Smartphones (landscape) ----------- */

/* iPads (landscape) ----------- */

@media only screen and (min-device-width: 320px) and (max-device-width: 1023px) and (orientation:landscape) {
  /* Styles */
  .titolo_home {
    width: 23rem;
    /*max-height: 33vh;*/
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .descr-intro {
    font-size: inherit;
    /* width: 90% !important; */
    margin-bottom: 0rem;
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
  }
  .bottone-inizia {
    width: 10rem !important;
    /* margin: 3rem auto; */
  }
  .storia>img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    margin: 0 auto;
  }
  .kaspersky {
    width: 10rem;
    margin-top: 0.5rem;
  }
  .apri-image {
    /* width: 16rem; */
    margin-top: 0rem;
    position: absolute;
    z-index: 0;
  }
  .apri {
    width: 5rem;
  }
  .bottone_img {
    z-index: 1;
  }
  .answer_button {
    /* width: 8rem; */
    border: none !important;
    background-color: transparent !important;
    /* height: 8rem; */
  }
  .bott_domande {
    width: 5rem;
    height: auto;
  }
  .risposta_corretta {
    position: absolute;
    width: 8rem;
    right: 0;
    margin-right: -4rem;
    margin-bottom: 1rem;
    bottom: 0;
  }
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .modal-dialog {
    margin-left: -1rem;
    /* width: 60vw; */
    margin: 2rem auto;
    /* overflow-y: hidden; */
  }
  .modal {
    overflow: hidden !important;
  }
  .modal-body {
    max-height: 7rem;
    font-size: inherit;
  }
  .res {
    font-size: larger;
  }
  .story_title {
    font-size: 1.3rem;
    margin-top: 1rem;
  }
  .avanti_button {
    right: 6rem;
    top: 1rem !important;
    margin-top: 0rem;
    /* margin-top: 0;
    position: absolute;
    top: 3rem;
    /* left: ; */
    /* transform: translate(50%, 70%); */
    /* width: 12rem; */
  }
  .avanti_button>img {
    width: 10rem;
  }
  .result_button {
    /* top: -50rem !important; */
    /* right: 7rem; */
  }
  .result_button>img {
    width: 11rem;
  }
  .result_text {
    font-size: 0.6rem;
    top: 1rem;
    /* display:inline-block; */
    /* vertical-align: middle; */
    /* position: absolute;
    top: 50%;
    left: 50%;
    height: 30%;
    width: 50%;
    margin: -15% 0 0 -25%; */
  }
  .result_title {
    /* width: 30vw;
    top: 30vh;
    font-size: larger; */
    /* display: inline-block; */
    /* display: inline-block; */
    /* vertical-align: middle; */
    /*margin-left:25vw !important; */
  }
  .si {
    margin-left: 6rem !important;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width:1365px) {
  /* Styles */
  .titolo_home {
    width: 40rem;
    /*max-height: 33vh;*/
  }
  .descr-intro {
    /* margin-bottom: 5rem; */
    /* max-height: 7rem; */
  }
  .modal-content {
    width: 100%;
  }
  .kaspersky {
    width: 20rem;
    /* margin-top: 4rem; */
  }
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .modal-dialog {
    margin-left: auto !important;
  }
  .avanti_button {
    right: 8rem;
  }
  .result_button {
    top: 1rem;
    /* right: 22rem; */
  }
  .result_text {
    font-size: x-large;
  }
  .result_title {
    width: 37vw;
    font-size: xx-large;
    /*margin-left:25vw !important; */
  }
  .apri-image {
    /* width: 15rem; */
  }
  .answer_button {
    /* height: 10rem; */
  }
  .modal-body {
    height: 16rem;
  }
  .si {
    margin-left: 9rem !important;
  }
}

@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) {
  /* Styles */
  .titolo_home {
    width: 60rem;
    /*max-height: 33vh;*/
  }
  .descr-intro {
    margin-bottom: 5rem;
    /* max-height: 10rem; */
  }
  .kaspersky {
    width: 20rem;
    /* margin-top: 6rem; */
  }
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .modal-dialog {
    margin-left: auto !important;
  }
  .result_button {
    top: 1rem;
    right: 22rem;
  }
  .result_text {
    font-size: x-large;
  }
  .result_title {
    width: 37vw;
    font-size: xx-large;
    /*margin-left:25vw !important; */
  }
  .result_text {
    font-size: 1.2rem;
    top: 6rem;
  }
  .result_title {
    width: 37vw;
    top: 5rem;
    /*margin-left:25vw !important; */
  }
  /* .storia {
    top: 7rem;
  } */
  .avanti_button {
    right: 5rem;
    /* width: 16rem; */
    /* height: 7rem; */
    top: 8rem;
  }
  .kaspersky {
    /* margin-top: 11rem; */
  }
  .si {
    margin-left: 9rem !important;
  }
}

@media only screen and (min-width: 812px) and (max-device-width: 892px) and (min-device-width:375px) and (orientation: landscape) {
  /* Styles */
  .titolo_home {
    width: 21rem;
    /*max-height: 33vh;*/
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .descr-intro {
    font-size: inherit;
    /* width: 90% !important; */
    margin-bottom: 0rem;
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
  }
  .bottone-inizia {
    width: 10rem !important;
    /* margin: 3rem auto; */
  }
  .kaspersky {
    width: 10rem;
    margin-top: -0.5rem;
  }
  .modal-body {
    max-height: -webkit-max-content;
    max-height: -moz-max-content;
    max-height: max-content;
    width: 30rem;
  }
  .apri-image {
    /* width: 16rem; */
  }
  .apri {
    width: 5rem;
  }
  .answer_button {
    /* width: 8rem; */
    border: none !important;
    background-color: transparent !important;
    /* height: 8rem; */
  }
  .bott_domande {
    width: 5rem;
    height: auto;
  }
  .risposta_corretta {
    position: absolute;
    width: 8rem;
    right: 0;
    margin-right: -4rem;
    margin-bottom: 0rem;
    bottom: 0;
  }
  #root {
    /* overflow: hidden; */
  }
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .modal-dialog {
    margin-left: auto;
    /* overflow-y: hidden; */
  }
  .modal {
    overflow: hidden !important;
  }
  .modal-body {
    max-height: 7rem;
    font-size: inherit;
  }
  .res {
    font-size: larger;
  }
  .story_title {
    font-size: 1.3rem;
  }
  .avanti_button {
    right: 5rem;
    top: 1rem;
    /* width: 12rem; */
  }
  .avanti_button>img {
    width: 10rem;
  }
  .result_button {
    top: 1rem;
    right: 7rem;
  }
  .result_button>img {
    width: 12rem;
  }
  .result_text {
    font-size: 0.7rem;
    top: 1rem;
  }
  .result_title {
    width: 37vw;
    top: 1rem;
    font-size: larger;
    /*margin-left:25vw !important; */
  }
  .si {
    margin-left: 6rem !important;
  }
}

/* Desktops and laptops ----------- */

@media only screen and (min-device-width: 1367px) {
  /* Styles */
  .titolo_home {
    width: 60rem;
    /*max-height: 33vh;*/
  }
  .descr-intro {
    /* margin-bottom: 5rem; */
  }
  .kaspersky {
    width: 20rem;
    /* margin-top: 4rem; */
  }
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .modal-dialog {
    margin-left: auto !important;
    width: 100%;
  }
  .avanti_button {
    right: 8rem;
  }
  .result_button {
    top: 1rem;
    right: 22rem;
  }
  .result_text {
    font-size: x-large;
  }
  .answer_button {
    /* width: 14rem; */
    /* height: 10rem; */
  }
  .result_title {
    width: 37vw;
    font-size: xx-large;
    /*margin-left:25vw !important; */
  }
  /* .storia {
    margin-top: -25rem;
  } */
  .si {
    margin-left: 8rem !important;
  }
}

/* Large screens ----------- */

/* @media only screen and (min-width: 1824px) {
} */